var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"rules":_vm.validations.required,"label":"Nimi","outlined":"","dense":""},model:{value:(_vm.currentLoan.name),callback:function ($$v) {_vm.$set(_vm.currentLoan, "name", $$v)},expression:"currentLoan.name"}}),_c('v-select',{attrs:{"items":_vm.loanMethods,"item-text":"text","item-value":"val","rules":_vm.validations.required,"label":"Lainan tyyppi","outlined":"","dense":""},model:{value:(_vm.currentLoan.method),callback:function ($$v) {_vm.$set(_vm.currentLoan, "method", $$v)},expression:"currentLoan.method"}}),_c('v-select',{attrs:{"items":_vm.globalValues.loanTypes,"item-text":"text","item-value":"val","rules":_vm.validations.required,"label":"Viitekoron tyyppi","outlined":"","dense":""},on:{"change":function($event){_vm.currentLoan.curRefRateIntStart = ''}},model:{value:(_vm.currentLoan.refRateType),callback:function ($$v) {_vm.$set(_vm.currentLoan, "refRateType", $$v)},expression:"currentLoan.refRateType"}}),_c('v-text-field',{attrs:{"type":"number","step":"0.001","max":"50","label":"Voimassa oleva viitekorko","rules":_vm.validations.required,"suffix":"%","outlined":"","dense":""},model:{value:(_vm.currentLoan.curRefRateInt),callback:function ($$v) {_vm.$set(_vm.currentLoan, "curRefRateInt", _vm._n($$v))},expression:"currentLoan.curRefRateInt"}}),(_vm.currentLoan.refRateType)?_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDate(_vm.currentLoan.curRefRateIntStart),"label":"Voimassa olevan korkojakson alkamispäivä","outlined":"","dense":"","append-icon":"mdi-calendar","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}],null,false,3531439789),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","allowed-dates":_vm.allowedDatesRefRate},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.currentLoan.curRefRateIntStart),callback:function ($$v) {_vm.$set(_vm.currentLoan, "curRefRateIntStart", $$v)},expression:"currentLoan.curRefRateIntStart"}})],1):_vm._e(),_c('v-text-field',{attrs:{"type":"number","step":"0.01","min":"0.01","max":"50","label":"Pankin marginaali","suffix":"%","outlined":"","dense":"","rules":_vm.validations.zeroOrGreater},model:{value:(_vm.currentLoan.margin),callback:function ($$v) {_vm.$set(_vm.currentLoan, "margin", _vm._n($$v))},expression:"currentLoan.margin"}}),(_vm.currentLoan.method == 'annuity' || _vm.currentLoan.method == 'equal instalment')?_c('v-menu',{ref:"menu3",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDate(_vm.currentLoan.endDate),"label":"Lainan päättymispäivä","outlined":"","dense":"","append-icon":"mdi-calendar","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}],null,false,1830297627),model:{value:(_vm.menu3),callback:function ($$v) {_vm.menu3=$$v},expression:"menu3"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","type":"month"},on:{"input":function($event){_vm.menu3 = false}},model:{value:(_vm.currentLoan.endDate),callback:function ($$v) {_vm.$set(_vm.currentLoan, "endDate", $$v)},expression:"currentLoan.endDate"}})],1):_vm._e(),_c('v-checkbox',{staticStyle:{"margin-top":"0","padding-top":"0"},attrs:{"label":"Lyhennysvapaa käytössä","outlined":"","dense":""},model:{value:(_vm.currentLoan.morgageExemptionActive),callback:function ($$v) {_vm.$set(_vm.currentLoan, "morgageExemptionActive", $$v)},expression:"currentLoan.morgageExemptionActive"}}),(_vm.currentLoan.morgageExemptionActive)?_c('v-menu',{ref:"menu4",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],attrs:{"value":_vm.formatDate(_vm.currentLoan.morgageExemptionEnds),"label":"Lyhennysvapaan päättymispäivä","outlined":"","dense":"","append-icon":"mdi-calendar","rules":_vm.validations.required}},'v-text-field',attrs,false),on))]}}],null,false,1542140111),model:{value:(_vm.menu4),callback:function ($$v) {_vm.menu4=$$v},expression:"menu4"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","type":"month","allowed-dates":_vm.allowedDatesMorgageExemption},on:{"input":function($event){_vm.menu4 = false}},model:{value:(_vm.currentLoan.morgageExemptionEnds),callback:function ($$v) {_vm.$set(_vm.currentLoan, "morgageExemptionEnds", $$v)},expression:"currentLoan.morgageExemptionEnds"}})],1):_vm._e(),_c('v-select',{attrs:{"outlined":"","dense":"","items":_vm.globalValues.loanHandlingTypes,"item-text":"text","item-value":"val","label":"Käsittely","rules":_vm.validations.required},model:{value:(_vm.currentLoan.handling),callback:function ($$v) {_vm.$set(_vm.currentLoan, "handling", $$v)},expression:"currentLoan.handling"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Poistu ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.submitBtnText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }