<template>
  <v-dialog v-model="dialog" width="450">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-text-field
            v-model="currentLoan.name"
            :rules="validations.required"
            label="Nimi"
            outlined
            dense
          ></v-text-field>

          <v-select
            v-model="currentLoan.method"
            :items="loanMethods"
            item-text="text"
            item-value="val"
            :rules="validations.required"
            label="Lainan tyyppi"
            outlined
            dense
          ></v-select>

          <v-select
            v-model="currentLoan.refRateType"
            :items="globalValues.loanTypes"
            item-text="text"
            item-value="val"
            :rules="validations.required"
            label="Viitekoron tyyppi"
            outlined
            dense
            @change="currentLoan.curRefRateIntStart = ''"
          ></v-select>

          <v-text-field
            v-model.number="currentLoan.curRefRateInt"
            type="number"
            step="0.001"
            max="50"
            label="Voimassa oleva viitekorko"
            :rules="validations.required"
            suffix="%"
            outlined
            dense
          ></v-text-field>

          <v-menu
            v-if="currentLoan.refRateType"
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.curRefRateIntStart)"
                label="Voimassa olevan korkojakson alkamispäivä"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :rules="validations.required"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.curRefRateIntStart"
              first-day-of-week="1"
              @input="menu2 = false"
              :allowed-dates="allowedDatesRefRate"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            v-model.number="currentLoan.margin"
            type="number"
            step="0.01"
            min="0.01"
            max="50"
            label="Pankin marginaali"
            suffix="%"
            outlined
            dense
            :rules="validations.zeroOrGreater"
          ></v-text-field>

          <v-menu
            v-if="currentLoan.method == 'annuity' || currentLoan.method == 'equal instalment'"
            ref="menu3"
            v-model="menu3"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.endDate)"
                label="Lainan päättymispäivä"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :rules="validations.required"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.endDate"
              first-day-of-week="1"
              type="month"
              @input="menu3 = false"
            ></v-date-picker>
          </v-menu>

          <v-checkbox
            v-model="currentLoan.morgageExemptionActive"
            label="Lyhennysvapaa käytössä"
            outlined
            dense
            style="margin-top: 0; padding-top: 0"
          ></v-checkbox>

          <v-menu
            v-if="currentLoan.morgageExemptionActive"
            ref="menu4"
            v-model="menu4"
            :close-on-content-click="false"
            min-width="290"
          >
            <template #activator="{ on, attrs }">
              <v-text-field
                :value="formatDate(currentLoan.morgageExemptionEnds)"
                label="Lyhennysvapaan päättymispäivä"
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                append-icon="mdi-calendar"
                v-prevent-manual-input
                :rules="validations.required"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="currentLoan.morgageExemptionEnds"
              first-day-of-week="1"
              type="month"
              @input="menu4 = false"
              :allowed-dates="allowedDatesMorgageExemption"
            ></v-date-picker>
          </v-menu>

          <v-select
            v-model="currentLoan.handling"
            outlined
            dense
            :items="globalValues.loanHandlingTypes"
            item-text="text"
            item-value="val"
            label="Käsittely"
            :rules="validations.required"
          ></v-select>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="error" outlined @click="dialog = false"> Poistu </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click.prevent="submit">{{ submitBtnText }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import mixins from "../../mixins/mixins";
import globalValues from "../../configs/globalValues";
import validations from "@/validations";
import moment from "moment";

export default {
  mixins: [mixins],

  props: {
    value: { type: Boolean, default: false },
    edit: { type: Boolean, default: false },
    title: { type: String, default: "Muokkaa lainaa" },
  },

  data() {
    return {
      loanMethods: [
        { text: "Annuiteetti", val: "annuity" },
        { text: "Tasalyhennys", val: "equal instalment" },
        // { text: "Kiinteä tasaerä", val: "fixed annuity" },
      ],
      menu2: false,
      menu3: false,
      menu4: false,
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("condominium", ["currentCondominium", "currentLoan", "loanIndex"]),

    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },

    submitBtnText() {
      return this.edit ? "Tallenna" : "Lisää laina";
    },
  },

  watch: {
    value(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      }
    },

    "currentLoan.method"(val) {
      if (val == "fixed annuity") {
        this.currentLoan.endDate = "";
      }
    },

    "currentLoan.morgageExemptionEnds"(val) {
      if (val)
        this.currentLoan.morgageExemptionEnds = moment(val).endOf("month").format("YYYY-MM-DD");
    },

    "currentLoan.endDate"(val) {
      if (val) this.currentLoan.endDate = moment(val).endOf("month").format("YYYY-MM-DD");
    },
  },

  methods: {
    ...mapMutations("condominium", ["addLoan", "editLoan", "setDefaultLoanForm"]),

    submit() {
      if (this.$refs.form.validate()) {
        // EDIT
        if (this.edit) {
          const boolean = this.currentCondominium.loans.some((el, index) => {
            if (index !== this.loanIndex && el.active) {
              return el.name.trim().toLowerCase() == this.currentLoan.name.trim().toLowerCase();
            }
          });

          if (!boolean) {
            this.editLoan();
            this.dialog = false;
            this.showPopup("Lainaa muokattu", "success");
          } else {
            return this.showPopup("Et voi lisätä saman nimisiä lainoja", "error");
          }
        } else {
          // NEW
          const boolean = this.currentCondominium.loans.some((el) => {
            if (el.active) {
              return el.name.trim().toLowerCase() == this.currentLoan.name.trim().toLowerCase();
            }
          });
          if (!boolean) {
            this.addLoan();
            this.dialog = false;
            this.showPopup("Laina lisätty", "success");
          } else {
            this.showPopup("Et voi lisätä saman nimisiä lainoja", "error");
          }
        }
      } else {
        this.showPopup("Tarkista kentät", "error");
      }
    },

    allowedDatesRefRate(val) {
      const today = moment().format("YYYY-MM-DD");

      const refRateType = this.currentLoan.refRateType;
      let lastPossibleDate;

      if (refRateType == "euribor 12") {
        lastPossibleDate = moment(today).subtract(12, "months").format("YYYY-MM-DD");
      } else if (refRateType == "euribor 6") {
        lastPossibleDate = moment(today).subtract(6, "months").format("YYYY-MM-DD");
      } else if (refRateType == "euribor 3") {
        lastPossibleDate = moment(today).subtract(3, "months").format("YYYY-MM-DD");
      }

      if (moment(val).isSameOrAfter(lastPossibleDate) && moment(val).isSameOrBefore(today))
        return true;
      else return false;
    },

    allowedDatesMorgageExemption(val) {
      if (moment(val).isAfter(moment())) return true;
      else return false;
    },
  },
};
</script>

<style></style>
