<template>
  <div>
    <h4>Lainat</h4>

    <div v-for="(loan, index) in currentCondominium.loans" :key="index">
      <!-- CARD -->
      <v-card v-if="loan.active" class="loan-card">
        <v-card-text class="loan-card-content">
          <div class="column">
            <p><strong>Nimi:</strong> {{ loan.name }}</p>
            <p><strong>Lainan tyyppi:</strong> {{ formatMethod(loan.method) }}</p>
            <p><strong>Käsittely: </strong>{{ formatHandling(loan.handling) }}</p>
          </div>
          <div class="column">
            <p v-if="loan.endDate">
              <strong>Maksuohjelma päättyy:</strong>
              {{ formatDate(loan.endDate) }}
            </p>
            <p>
              <strong>Lyhennysvapaata: </strong>{{ loan.morgageExemptionActive ? "Kyllä" : "Ei" }}
            </p>
            <p v-if="loan.morgageExemptionActive">
              <strong>Lyhennysvapaa loppuu: </strong>{{ formatDate(loan.morgageExemptionEnds) }}
            </p>
          </div>
          <div class="column">
            <p><strong>Viitekoron tyyppi:</strong> {{ loan.refRateType }}</p>
            <p>
              <strong>Viitekorko:</strong> {{ loan.curRefRateInt }} %, alk.
              {{ formatDate(loan.curRefRateIntStart) }}
            </p>
            <p><strong>Marginaali:</strong> {{ loan.margin }} %</p>
          </div>
          <!-- Add other fields here -->
          <div class="actions">
            <v-icon small @click="editCurrentLoan(loan, index)">mdi-pencil</v-icon>
            <v-icon small color="error" @click="deleteCurrentLoan(loan, index)">mdi-delete</v-icon>
          </div>
        </v-card-text>
      </v-card>
    </div>

    <v-row dense>
      <v-col
        ><v-btn @click="newLoan('Luo laina')" small color="primary">Luo uusi laina</v-btn></v-col
      >
    </v-row>

    <!-- Loan form -->
    <condominium-loan-form
      v-model="loanFormDialog"
      :title="loanFormTitle"
      :edit="editLoan"
    ></condominium-loan-form>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import validations from "@/validations";
import globalValues from "../../configs/globalValues";
import mixins from "@/mixins/mixins";
import CondominiumLoanForm from "./CondominiumLoanForm";

export default {
  mixins: [mixins],

  components: { CondominiumLoanForm },

  data() {
    return {
      loanFormDialog: false,
      loanFormTitle: "",
      editLoan: false,
      menu: {},
      globalValues,
      validations,
    };
  },

  computed: {
    ...mapState("condominium", ["currentCondominium"]),
  },

  methods: {
    ...mapMutations("condominium", ["deleteLoan", "setDefaultLoanForm", "setCurrentLoan"]),

    newLoan(title) {
      this.setDefaultLoanForm();
      this.editLoan = false;
      this.loanFormTitle = title;
      this.loanFormDialog = true;
    },

    editCurrentLoan(loan, index) {
      this.setCurrentLoan({ loan, index });
      this.editLoan = true;
      this.loanFormTitle = `Muokkaa lainaa ${loan.name}`;
      this.loanFormDialog = true;
    },

    deleteCurrentLoan(loan, index) {
      // This either removes the loan or transfers it to old loans
      if (loan._id) {
        const answer = confirm(
          `Haluatko poistaa lainan ${loan.name}? Rahoitusvastike poistetaan tallennettaessa automaattisesti myös vuokrakohteiden nykyisistä rahoitusvastikkeista, mutta entiset jätetään paikoilleen.`
        );
        if (answer) {
          loan.active = false;
          this.showPopup("Laina poistettu", "success");
        }
      } else {
        this.deleteLoan(index);
      }
    },

    formatMethod(method) {
      if (method == "annuity") return "Annuiteetti";
      if (method == "equal instalment") return "Tasalyhenteinen";
      if (method == "fixed annuity") return "Kiinteä tasaerä";
    },

    formatHandling(text) {
      if (text === "funding") return "Rahastoitu";
      if (text == "income recognition") return "Tuloutettu";
      else return "";
    },
  },
};
</script>

<style scoped>
.loan-card {
  margin-bottom: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.loan-card-content {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  margin: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
  margin: 10px;
}

@media (max-width: 960px) {
  .column {
    flex-basis: calc(33.33% - 20px);
  }

  .actions {
    flex-direction: row;
  }
}

@media (max-width: 768px) {
  .column {
    flex-basis: calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .column {
    flex-basis: calc(100% - 20px);
    margin: 5px;
  }

  .actions {
    margin: 5px;
  }
}
</style>
